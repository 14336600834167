import * as React from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { serviceBackground, serviceIcon } from './service.utils';
import { Service as Fragment } from '../../data';

interface Props extends Fragment {
  reverse?: boolean;
}

export const Service: React.SFC<Props> = ({ html, meta, reverse = false }) => {
  const icon = serviceIcon(meta.slug);
  const color = serviceBackground(meta.slug);
  const containerClass = classNames('flex flex-wrap items-center', {
    'md:flex-row-reverse': reverse,
  });

  return (
    <div className="service bg-white">
      <div className="container mx-auto py-12">
        <div className={containerClass}>
          <div className="w-full px-4 md:w-1/2 md:px-0">
            <h2>{meta.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <div className="w-full md:w-1/2">
            <div
              className="hidden md:flex items-center justify-center mx-auto shadow-lg"
              style={{
                height: 250,
                width: 250,
                backgroundColor: color,
              }}
            >
              <img src={icon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServiceFragment = graphql`
  fragment Service on MarkdownRemark {
    html
    meta: frontmatter {
      title
      order
      slug
    }
  }
`;
