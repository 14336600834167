import * as React from 'react';
import { Field, FieldAttributes } from 'formik';
import './form-input.css';

interface Props {
  name: string;
  label?: string;
  type?: string;
}

export const FormikInput: React.SFC<Props & FieldAttributes<any>> = ({
  name,
  type = 'text',
  label = null,
  ...fieldProps
}) => (
  <div className="bg-white border-1 border-grey-light rounded-sm mb-4">
    {label !== null && (
      <label
        htmlFor={name}
        style={{ color: '#626262' }}
        className="font-serif font-bold tracking-wide text-xs uppercase block p-2 pb-0"
      >
        {label}
      </label>
    )}
    <Field
      name={name}
      type={type}
      className="block text-base bg-white border-none p-2 shadow-none outline-none text-grey-darker w-full form-control"
      {...fieldProps}
    />
  </div>
);
