import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { HtmlAttributes } from 'csstype';

interface ButtonProps {
  color: 'purple' | 'pink';
  disabled?: boolean;
}

interface LinkProps extends ButtonProps {
  to: string;
}

const styles = (color: string) =>
  classNames(
    'px-8 py-4 text-white uppercase font-serif text-sm tracking-wide',
    { 'bg-purple': color === 'purple' },
    { 'hover:bg-purple-dark': color === 'purple' },
    { 'bg-pink': color === 'pink' },
    { 'hover:bg-pink-dark': color === 'pink' }
  );

export const Button: React.SFC<
  React.ButtonHTMLAttributes<{}> & ButtonProps
> = ({ color = 'pink', ...props }) => (
  <button className={styles(color)} {...props} />
);

export const ButtonLink: React.SFC<LinkProps> = ({
  to,
  children,
  color = 'pink',
}) => {
  return (
    <Link to={to} className={styles(color)}>
      {children}
    </Link>
  );
};
