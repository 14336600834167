import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const favicon16 = require('../../assets/favicon/favicon-16x16.png');
const favicon32 = require('../../assets/favicon/favicon-32x32.png');

interface Props {
  description?: string;
  lang?: string;
  meta?: {
    name: string;
    content: string;
  }[];
  keywords?: string[];
  title: string;
}

export const SEO: React.SFC<Props> = ({
  description,
  lang = 'en',
  meta = [],
  keywords = [],
  title,
}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            link={[
              {
                rel: 'icon',
                type: 'image/png',
                href: `${favicon32}`,
                sizes: '32x32',
              },
              {
                rel: 'icon',
                type: 'image/png',
                href: `${favicon16}`,
                sizes: '16x16',
              },
            ]}
            meta={[
              { name: `description`, content: metaDescription },
              { property: `og:title`, content: title },
              { property: `og:description`, content: metaDescription },
              { property: `og:type`, content: `website` },
              { name: `twitter:card`, content: `summary` },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              { name: `twitter:title`, content: title },
              { name: `twitter:description`, content: metaDescription },
            ]
              .concat(
                keywords.length > 0
                  ? { name: `keywords`, content: keywords.join(`, `) }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
};

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
