const bgColors = {
  laravel: '#FB503B',
  graphql: '#E535AB',
  react: '#61DAFB',
  team: '#5648ED',
  consult: '#3071F5',
  design: '#241F32',
};

const icons = {
  laravel: require('../../assets/icons/icon-laravel-service.svg'),
  graphql: require('../../assets/icons/icon-graphql-service.svg'),
  react: require('../../assets/icons/icon-react-service.svg'),
  team: require('../../assets/icons/icon-team-service.svg'),
  design: require('../../assets/icons/icon-design-service.svg'),
  consult: require('../../assets/icons/icon-consult-service.svg'),
};

export const serviceBackground = (service: string) => bgColors[service];
export const serviceIcon = (service: string) => icons[service];
