import * as React from 'react';

export const FooterHeader: React.SFC = props => (
  <h4 className="uppercase tracking-wide text-base mb-6 font-serif">
    {props.children}
  </h4>
);

interface ContactProps {
  title: string;
}

export const FooterContact: React.SFC<ContactProps> = ({ title, children }) => (
  <p>
    <span className="font-serif uppercase font-bold text-grey-dark text-sm">
      {title}
    </span>
    <br />
    <span>{children}</span>
  </p>
);
