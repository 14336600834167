import * as React from 'react';
import { graphql } from 'gatsby';
import { HomeHero as HomeHeroProps } from '../../data';
import './hero.css';
import { ButtonLink } from '../../components';

export const Hero: React.SFC = ({ children }) => (
  <div className="hero-figure bg-no-repeat bg-cover bg-center">{children}</div>
);

export const HomeHero: React.SFC<HomeHeroProps> = props => (
  <Hero>
    <div className="container mx-auto">
      <div className="py-12 text-center">
        <h3 className="text-white font-normal uppercase font-serif tracking-wide text-4xl mb-0">
          {props.tagline}
        </h3>
        {/* <p className="text-white tracking-wide font-serif font-bold">
                  {frontmatter.subTagline}
                </p> */}
        <div className="nuwave-tools flex flex-wrap justify-center py-8">
          {props.tools.map((tool, i) => {
            return (
              <React.Fragment key={i}>
                {i !== 0 && (
                  <p className="text-white text-base font-serif font-bold mb-0">
                    /
                  </p>
                )}
                <p
                  key={i}
                  className="px-2 md:px-4 text-base text-white font-bold mb-0 tool-item font-serif uppercase tracking-wide"
                >
                  {tool}
                </p>
              </React.Fragment>
            );
          })}
        </div>
        <div className="flex w-full justify-center">
          <ButtonLink to="/contact" color="pink">
            Work With Us
          </ButtonLink>
        </div>
      </div>
    </div>
  </Hero>
);

export const HomeHeroFragment = graphql`
  fragment HomeHero on MarkdownRemarkFrontmatter {
    title
    path
    tagline
    subTagline
    tools
  }
`;

interface PageHeroProps {
  title: string;
  subTitle?: string;
}

export const PageHero: React.SFC<PageHeroProps> = ({ title }) => (
  <Hero>
    <div className="container mx-auto px-4 px-0">
      <h2 className="text-white tracking-wide text-4xl mb-0">{title}</h2>
    </div>
  </Hero>
);
