import * as React from 'react';
import { Link } from 'gatsby';

export const HeaderLink: React.SFC<{ to: string }> = props => (
  <Link
    className="flex font-serif font-normal uppercase text-sm px-8 items-center text-white tracking-wide hover:text-purple-light cursor-pointer"
    to={props.to}
  >
    {props.children}
  </Link>
);

export const MobileLink: React.SFC<{ to: string }> = props => (
  <Link
    to={props.to}
    className="w-full bg-grey-darkest py-6 px-4 font-serif font-bold uppercase text-base text-white"
  >
    {props.children}
  </Link>
);
