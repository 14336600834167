import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Header } from '../header';
import { Footer } from '../footer';

import './layout.css';

export const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer />
      </>
    )}
  />
);
