import * as React from 'react';
import classNames from 'classnames';
import { Link, graphql } from 'gatsby';
import { SkillTile as Fragment } from '../../data';

const arrowIconWhite = require('../../assets/icons/icon-arrow-right.svg');
const arrowIconDark = require('../../assets/icons/icon-arrow-right-dark.svg');

const icons = {
  enterprise: require('../../assets/icons/icon-database.svg'),
  startup: require('../../assets/icons/icon-rocket.svg'),
  team: require('../../assets/icons/icon-team.svg'),
  laravel: require('../../assets/icons/icon-laravel-orange.svg'),
  services: require('../../assets/icons/icon-services-badges.svg'),
};

const bgColor = {
  blue: 'bg-blue',
  purple: 'bg-purple',
  dark: 'bg-grey-darkest',
  light: 'bg-grey-lightest',
};

export const SkillTile: React.SFC<Fragment> = ({
  to,
  icon,
  color,
  title,
  content,
}) => {
  const arrowIcon = color !== 'light' ? arrowIconWhite : arrowIconDark;
  const h3Class = classNames(
    'mb-4 font-serif uppercase tracking-wide leading-loose text-lg lg:text-xl',
    { 'text-white': color !== 'light' }
  );

  const pClass = classNames('font-sans leading-loose mb-8', {
    'text-white': color !== 'light',
  });

  return (
    <div className="w-full md:flex md:w-1/2 lg:w-1/4 px-2 md:px-4 mb-4 md:mb-8 lg:mb-0 items-stretch">
      <div className={`flex-1 p-4 lg:p-8 ${bgColor[color]}`}>
        <div>
          <img src={icons[icon]} className="mb-8" />
          <h3 className={h3Class}>{title}</h3>
          <p className={pClass}>{content}</p>
          <Link to={to} className="mb-4">
            <img src={arrowIcon} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const SkillTileFragment = graphql`
  fragment SkillTile on MarkdownRemarkFrontmatterSkills {
    title
    icon
    color
    to
    title
    content
  }
`;
