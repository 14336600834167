import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GraphQLBlockQuery } from '../../data';

const diagram = require('../../assets/icons/icon-graphql-diagram.svg');

export const GraphQLBlock: React.SFC = () => (
  <StaticQuery
    query={GRAPHQL_QUERY}
    render={({ markdownRemark }: GraphQLBlockQuery) => (
      <section className="bg-white">
        <div className="container mx-auto py-12">
          <div className="sm:flex-wrap md:flex md:items-center">
            <div
              className="w-full md:w-1/2 px-4"
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            />
            <div className="w-full md:w-1/2 px-4 pt-8 md:pt-0">
              <img src={diagram} alt="GraphQL Diagram" />
            </div>
          </div>
        </div>
      </section>
    )}
  />
);

const GRAPHQL_QUERY = graphql`
  query GraphQLBlockQuery {
    markdownRemark(
      frontmatter: { slug: { eq: "graphql" }, type: { eq: "block" } }
    ) {
      id
      html
    }
  }
`;
