import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { menuItems } from './header.state';
import { HeaderLink, MobileLink } from './header.views';

const logo = require('./logo.svg');
const menuIcon = require('../../assets/icons/icon-menu.svg');
const menuIconClose = require('../../assets/icons/icon-menu-close.svg');

interface State {
  isOpen: boolean;
}

export class Header extends React.Component<{}, State> {
  state: State = {
    isOpen: false,
  };

  render() {
    const menuContainerClass = classNames(
      'menu-mobile md:hidden bg-grey-darkest',
      {
        'absolute pin-x pin-t pin-b z-10': this.state.isOpen,
      }
    );

    const menuClass = classNames('flex justify-between items-center flex-row');

    return (
      <div className="container-fluid bg-white">
        <div className="menu-full hidden md:block">
          <div className="flex justify-between">
            <div className="flex flex-row items-center">
              <Link to="/" className="flex bg-purple hover:bg-purple-dark p-4">
                <img src={logo} />
              </Link>
              <h1 className="text-sm uppercase font-serif ml-4 tracking-wide">
                <Link className="text-white" to="/">
                  NuWave Commerce
                </Link>
              </h1>
            </div>
            <div className="flex flex-row">
              {menuItems.map((item, i) => (
                <HeaderLink key={i} to={item.to}>
                  {item.text}
                </HeaderLink>
              ))}
            </div>
          </div>
        </div>
        <div className={menuContainerClass}>
          <div className={menuClass}>
            <Link to="/" className="flex bg-purple hover:bg-purple-dark p-3">
              <img src={logo} style={{ height: 30 }} />
            </Link>
            <a
              href="#"
              className="flex px-4"
              onClick={e => {
                e.preventDefault();
                this.setState(state => ({ isOpen: !state.isOpen }));
              }}
            >
              {!this.state.isOpen && (
                <img src={menuIcon} style={{ height: 17 }} />
              )}
              {this.state.isOpen && (
                <img src={menuIconClose} style={{ height: 17 }} />
              )}
            </a>
          </div>
          {this.state.isOpen && (
            <div className="menu-list flex flex-col">
              {menuItems.map((item, i) => (
                <MobileLink key={i} to={item.to}>
                  {item.text}
                </MobileLink>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
