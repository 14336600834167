import * as React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { FooterComponentQuery } from '../../data';
import { FooterHeader, FooterContact } from './footer.views';
import { ButtonLink } from '../../components';

export const Footer: React.SFC = () => (
  <StaticQuery
    query={FOOTER_QUERY}
    render={(data: FooterComponentQuery) => (
      <footer className="bg-white border-t-1 border-grey-light">
        <div className="container mx-auto">
          <div className="flex flex-wrap py-12">
            <div className="w-full pb-8 md:pb-0 md:w-1/4 px-4">
              <FooterHeader>Who We Are</FooterHeader>
              <p>
                {data.markdownRemark.html
                  .replace('<p>', '')
                  .replace('</p>', '')}
              </p>
              <div className="pt-6 flex">
                <ButtonLink to="/contact" color="purple">
                  Work With Us
                </ButtonLink>
              </div>
            </div>

            <div className="w-full pb-8 md:pb-0 md:w-1/4 px-4">
              <FooterHeader>Trending News</FooterHeader>
              <p>Coming Soon!</p>
            </div>

            <div className="w-full pb-8 md:pb-0 md:w-1/4 px-4">
              <FooterHeader>Contact Us</FooterHeader>
              <FooterContact title="Phone Number">
                {data.markdownRemark.frontmatter.phoneNumber}
              </FooterContact>
              <FooterContact title="Email Address">
                {data.markdownRemark.frontmatter.email}
              </FooterContact>
              <FooterContact title="Business Hours">
                {data.markdownRemark.frontmatter.businessHours}
              </FooterContact>
            </div>
            <div className="w-full pb-8 md:pb-0 md:w-1/4 px-4">
              <FooterHeader>Useful Links</FooterHeader>
              <p>
                <Link
                  className="font-serif font-bold uppercase text-base text-grey-darker hover:text-grey-darkest"
                  to="/"
                >
                  Home
                </Link>
              </p>
              <p>
                <Link
                  className="font-serif font-bold uppercase text-base text-grey-darker hover:text-grey-darkest"
                  to="/about"
                >
                  About
                </Link>
              </p>
              <p>
                <Link
                  className="font-serif font-bold uppercase text-base text-grey-darker hover:text-grey-darkest"
                  to="/services"
                >
                  Services
                </Link>
              </p>
              <p>
                <Link
                  className="font-serif font-bold uppercase text-base text-grey-darker hover:text-grey-darkest"
                  to="/contact"
                >
                  Contact Us
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
);

const FOOTER_QUERY = graphql`
  query FooterComponentQuery {
    markdownRemark(frontmatter: { slug: { eq: "footer" } }) {
      id
      html
      frontmatter {
        phoneNumber
        email
        businessHours
      }
    }
  }
`;
